<template>
<div>
    <div class="orderHeader">
        <img :src="require('../assets/logoOrders.png')" />
        <div class="orderTime">
            <div id="time">{{hour}}:{{minuts}}</div>
        </div>
    </div>
    <div class="orderRaw">
        <div class="orderRaw__item">
            <div class="orderRaw__item-header">
                Готовятся
            </div>
            <div class="orderItem25Items">
                <div class="orderItem25" v-for="item in assembly.data.orders" :key="item.id">{{item.unique_code}}</div>
            </div>
        </div>
        <div class="orderRaw__item">
            <div class="orderRaw__item-header orderRaw__item-header--orange">
                Готовы
            </div>
            <div class="orderItem33" v-for="item in assembled.data.orders" :key="item.id">{{item.unique_code}}</div>
        </div>
    </div>
</div>
</template>

<script>
import {
    BASE_URL
} from './../consts';
import axios from "axios";
export default {
    data() {
        return {
            state: true,
            currentTime: Date.now(),
            interval: null,
            assembly: [],
            assembled: [],
            hour: null,
            minuts: null
            //hours:null
        }
    },
      head: {
        title: {
            inner: 'Заказы',
            separator: '-',
            complement: 'Ели Млели'
        },
    },
    mounted: function () {
        this.interval = setInterval(this.updateCurrentTime, 1000);
        this.callBell(100);
        // this.getOrdersAssembly()
        // this.getOrdersAssembled()
    },
    computed: {
        // time: function () {
        //     return this.hours + ":" + this.minutes;
        // },
        // milliseconds: function () {
        //     return this.currentTime;
        // },
        // hours: function () {

        //     // Doing the math
        //     //  var milli = this.milliseconds;
        //     //  var hrs = Math.floor((milli / 3600000) % 24);
        //     // Using getHours()
        //     var hrs = new Date().getHours();

        //     return hrs >= 10 ? hrs : '0' + hrs;
        // },
        // minutes: function () {
        //     var milli = this.milliseconds;
        //     var min = Math.floor((milli / 60000) % 60);
        //     return min >= 10 ? min : '0' + min;
        // },
        // seconds: function() {
        //   var milli = this.milliseconds;
        //   var sec = Math.ceil((milli % 60000) / 1000).toFixed(0);
        //   if (sec == 60) { sec = 0 }
        //   return sec >= 10 ? sec : '0' + sec;
        // }
    },
    methods: {
        async getOrdersAssembly() {
            try {
                const res = await axios({
                    url: `${BASE_URL}/operator/order/getlist?status_id=[3]`,
                });
                this.assembly = res
            } catch (error) {
                alert(error)
            }

        },
        async getOrdersAssembled() {
            try {
                const res = await axios({
                    url: `${BASE_URL}/operator/order/getlist?status_id=[4]`,
                });
                this.assembled = res
            } catch (error) {
                alert(error)
            }

        },
        // reset: function() {
        //   this.state = true;
        //   this.currentTime = Date.now();
        // },
        // pause: function() {
        //   this.state = false;
        // },
        // resume: function() {
        //   this.state = true;
        // },
        // updateCurrentTime: function () {
        //     if (this.state == true) {
        //         this.currentTime = Date.now();
        //     }
        // },
        async callBell(counter) {
            var hrs = new Date().getHours();
            hrs = hrs.toString()
            if (hrs.length == 1) {
                this.hour = `0${hrs}`
            } else {
                this.hour = hrs
            }
            var min = new Date().getMinutes();
            min = min.toString()
            if (min.length == 1) {
                this.minuts = `0${min}`
            } else {
                this.minuts = min
            }

            if (this.$store.state.auth.token_status) {
                var th = this
                console.log(counter);
                counter++;
                await th.getOrdersAssembly()
                await th.getOrdersAssembled()
                //  let date = new Date();
                //  this.hours = date.getHours();
                window.setTimeout(function () {
                    th.callBell(counter);
                }, 10000)
            }
        }
    }

}
</script>

<style lang="scss">
.orderRaw {
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    //font-display: column;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    width: 100%;

    &__item {
        width: calc(50% - 40px);
    }

    &__item-header {
        text-align: center;
        background: #3F2A2C;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 55px;
        line-height: 100.74%;
        height: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 25px;
        text-transform: uppercase;

        color: #FFFFFF;

    }

    &__item-header--orange {
        background: #FF7728;
        margin-bottom: 50px;
    }
}

.orderItem25 {
    width: calc(25% - 12px);
    height: 90px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #EAEAEA;
    border-radius: 9px;
    margin-right: 12px;
    margin-bottom: 12px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 56px;
    line-height: 100.74%;
}

.orderItem33 {
    color: #FF7728;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 80px;
    line-height: 1;
    margin-bottom: 40px;
    /* or 201px */

    text-transform: uppercase;

    color: #FF7728;

}

.orderItem25Items {
    display: flex;
    flex-wrap: wrap;
}

.orderHeader {
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    margin-top: 27px;
    align-items: center;
    margin-bottom: 45px;

    img {
        max-width: 200px;
        height: auto;
    }
}

.orderTime {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 90px;
    line-height: 100.74%;
    /* or 181px */

    text-transform: uppercase;

    color: #000000;
}
</style>
